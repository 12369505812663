import React, { SyntheticEvent } from 'react';
import { Stack, Tabs, Tab, Typography } from '@mui/material';
import {
  Restaurant,
  Catering,
  SpaceRental,
  Activity,
  Patio,
  PrivateDining,
  AllVenues,
} from '../../../components/icons/CategoryIcons';
import { PVGray, PVBlack } from '@pv/common/colors';

interface CategorySelectorProps {
  selectedCategory: string;
  setSelectedCategory: (category: string) => void;
}

const categories = [
  { label: 'All Venues', icon: <AllVenues /> },
  { label: 'Restaurant', icon: <Restaurant /> },
  { label: 'Catering', icon: <Catering /> },
  { label: 'Space Rental', icon: <SpaceRental /> },
  { label: 'Activity', icon: <Activity /> },
  { label: 'Patio', icon: <Patio /> },
  { label: 'Private Dining', icon: <PrivateDining /> },
];

export const CategorySelector = ({
  selectedCategory,
  setSelectedCategory,
}: CategorySelectorProps) => {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{ width: '100%' }}
    >
      <Tabs
        value={selectedCategory}
        onChange={(e: SyntheticEvent, v: any) => setSelectedCategory(v)}
        variant="scrollable"
        scrollButtons="auto"
        allowScrollButtonsMobile
        sx={{
          '& .MuiTabs-indicator': {
            backgroundColor: 'text.primary',
          },
        }}
      >
        {categories.map((category) => (
          <Tab
            key={category.label}
            icon={category.icon}
            iconPosition="top"
            label={category.label}
            value={category.label}
            onClick={() => setSelectedCategory(category.label)}
            sx={{
              padding: 0,
              margin: '0px 16px',
              minWidth: '60px',
              color: PVGray,
              fontSize: '12px',
              '&.Mui-selected': {
                color: '#222222',
              },
            }}
          />
        ))}
      </Tabs>
    </Stack>
  );
};
