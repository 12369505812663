import React, { FC } from 'react';
import styled from 'styled-components';

import { CircularProgress } from '@mui/material';

/*
 * Types.
 */

interface LoadingIndicatorProps {}

/*
 * Constants.
 */

const StyledLoadingDiv = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

/*
 * Component.
 */

export const LoadingIndicator: FC<LoadingIndicatorProps> = () => (
  <StyledLoadingDiv>
    <CircularProgress />
  </StyledLoadingDiv>
);
