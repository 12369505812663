import React, { FC, ReactNode, useState, useCallback, memo } from 'react';
import { GoogleMap } from '@react-google-maps/api';
import { mapStyles } from './MapStyles';

import { VoidFn } from '../types/common';

/*
 * Types.
 */

interface GoogleMapContainerStyle {
  width?: string;
  height?: string;
}

interface GoogleMapCenter {
  lat: number;
  lng: number;
}

interface GoogleMapProviderProps {
  children: ReactNode;
  containerStyle: GoogleMapContainerStyle;
  center?: GoogleMapCenter;
  zoom?: number;
  handleLoad?: VoidFn<google.maps.Map>;
}

/*
 * Constants.
 */

const DEFAULT_ZOOM = 12;

/*
 * Components.
 */

const GoogleMapProvider: FC<GoogleMapProviderProps> = ({
  children,
  containerStyle,
  center,
  zoom = DEFAULT_ZOOM,
  handleLoad,
}: GoogleMapProviderProps) => {
  const [, setMap] = useState<google.maps.Map>();

  const defaultOnLoad = useCallback(
    (map: google.maps.Map) => {
      map.setZoom(zoom);
      setMap(map);
    },
    [zoom],
  );

  const onUnmount = useCallback((map: google.maps.Map) => {
    setMap(undefined);
  }, []);

  /*
   * Note: the children component here is meant for markers, info windows, etc.
   */
  return (
    <GoogleMap
      mapContainerStyle={containerStyle}
      center={center}
      zoom={zoom}
      onLoad={handleLoad ?? defaultOnLoad}
      onUnmount={onUnmount}
      options={{
        styles: mapStyles,
        mapTypeControl: false,
        fullscreenControl: false,
        streetViewControl: false,
      }}
    >
      {children}
    </GoogleMap>
  );
};

export default memo(GoogleMapProvider);
