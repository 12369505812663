import React from 'react';
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from '@mui/material';
import { Procedure } from '../types/common';
import { MappableItem } from './GoogleMap';
import { SpaceCarousel } from './SpaceCarousel';

interface VenueMapPreviewProps {
  venue: MappableItem;
  handleClick?: Procedure;
}

export const VenueMapPreview = ({
  venue,
  handleClick,
}: VenueMapPreviewProps) => {
  const spaces = venue.spaces || [];
  const spacesWithImages = spaces.filter((space) => space.images.length > 0);

  const onClickCard = () => {
    handleClick && handleClick();
  };

  if (spacesWithImages.length > 0) {
    return (
      <SpaceCarousel spaces={spacesWithImages} handleClick={handleClick} />
    );
  }

  const image =
    venue.images?.[0]?.url || venue.profileBanner || '/default_banner.jpg';

  return (
    <Card
      style={{
        borderRadius: '0px',
        width: '230px',
        padding: '0px 0px 10px 8px',
      }}
    >
      <CardActionArea
        disableRipple={true}
        onClick={onClickCard}
        sx={{
          '&:hover .MuiCardActionArea-focusHighlight': {
            opacity: 0,
          },
        }}
      >
        <>
          <CardMedia
            height="173px"
            width="230px"
            component="img"
            image={image}
            title={venue.name}
          />

          <CardContent
            style={{
              padding: '10px 0px 0px 0px',
              gap: '2px',
            }}
          >
            <Box>
              <Typography variant="body1" component="h4" noWrap>
                {venue.name}
              </Typography>
              <Typography variant="body2" component="p">
                {venue.address?.streetAddress1}, {venue.address?.city},{' '}
                {venue.address?.state}
              </Typography>
            </Box>
          </CardContent>
        </>
      </CardActionArea>
    </Card>
  );
};
