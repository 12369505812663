import React, { FC } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import type { SelectChangeEvent } from '@mui/material';

import type {
  EventStyle,
  GuestRange,
} from '../../../types/components/filter_bar_types';
import type { VoidFn, Procedure } from '../../../types/common';
import { WebFilterBar } from './WebFilterBar';
import { MobileFilterBar } from './MobileFilterBar';

/*
 * Types
 */

export interface FilterBarProps {
  locationQuery: string;
  onChangeLocationQuery: VoidFn<string>;

  eventStyle: EventStyle;
  onChangeEventStyle: VoidFn<SelectChangeEvent<EventStyle>>;
  clearEventStyle: Procedure;

  guestRange: GuestRange;
  onChangeGuestRange: VoidFn<SelectChangeEvent<GuestRange>>;
  clearGuestRange: Procedure;
}

export const LocationSearchFilterBar: FC<FilterBarProps> = (
  props: FilterBarProps,
) => {
  const theme = useTheme();
  const mobileMedia = useMediaQuery(theme.breakpoints.down('md'));

  return mobileMedia ? (
    <MobileFilterBar {...props} />
  ) : (
    <WebFilterBar {...props} />
  );
};
