import React, { useEffect, useState } from 'react';
import {
  Box,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  Typography,
} from '@mui/material';
import { Space } from '../types/models/space_types';
import { Procedure } from '../types/common';
import { ChevronLeft, ChevronRight, Users } from 'react-feather';

interface SpaceCarouselProps {
  spaces: Space[];
  handleClick?: Procedure;
}

export const SpaceCarousel = ({
  spaces = [],
  handleClick,
}: SpaceCarouselProps) => {
  const [index, setIndex] = useState<number>(0);
  const [space, setSpace] = useState<Space | undefined>();

  useEffect(() => {
    const s = spaces && spaces[index % spaces.length];
    if (s) {
      setSpace(s);
    } else {
      setSpace(undefined);
    }
  }, [index, spaces]);

  const onClickCard = () => {
    handleClick && handleClick();
  };

  const onPrev = () => {
    const prevIndex = index - 1;

    setIndex(prevIndex < 0 ? spaces.length - 1 : prevIndex);
  };

  const onNext = () => {
    const nextIndex = index + 1;

    setIndex(nextIndex % spaces.length);
  };

  const getCapacityString = ({
    standing,
    seated,
  }: {
    standing?: number | null;
    seated?: number | null;
  }) => {
    const capacities = [
      standing ? `${standing} Standing` : null,
      seated ? `${seated} Seated` : null,
    ].filter(Boolean);

    return capacities.join(', ');
  };

  return (
    <Card
      style={{
        borderRadius: '0px',
        width: '230px',
        padding: '0px 0px 10px 8px',
      }}
    >
      <CardActionArea
        disableRipple={true}
        onClick={onClickCard}
        sx={{
          '&:hover .MuiCardActionArea-focusHighlight': {
            opacity: 0,
          },
        }}
      >
        {space && (
          <>
            <CardMedia
              height="173px"
              width="230px"
              component="img"
              image={space.images[0].url}
              title={space.name}
            />
            {spaces.length > 1 && (
              <CardActions
                style={{
                  width: '100%',
                  position: 'absolute',
                  top: 72,
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: 0,
                }}
              >
                <IconButton
                  aria-label="previous"
                  onClick={(e) => {
                    e.stopPropagation();
                    onPrev();
                  }}
                  style={{
                    padding: '0px',
                  }}
                >
                  <ChevronLeft color="white" />
                </IconButton>
                <IconButton
                  aria-label="next"
                  onClick={(e) => {
                    e.stopPropagation();
                    onNext();
                  }}
                  style={{
                    padding: '0px',
                  }}
                >
                  <ChevronRight color="white" />
                </IconButton>
              </CardActions>
            )}
            <CardContent
              style={{
                padding: '10px 0px 0px 0px',
                gap: '2px',
              }}
            >
              <Box>
                <Typography
                  variant="subtitle1"
                  component="h6"
                  style={{
                    height: '24px',
                    lineHeight: '24px',
                  }}
                  noWrap
                >
                  {space.name}
                </Typography>
              </Box>
              <Box>
                <Typography variant="body1" component="h4" noWrap>
                  {space.venue?.name}
                </Typography>
              </Box>
              <Box
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  height: '20px',
                  gap: '8px',
                }}
              >
                {(space.standing || space.seated) && (
                  <>
                    <Users size={16} />
                    <Typography variant="body2" color="textSecondary">
                      {getCapacityString({
                        standing: space.standing,
                        seated: space.seated,
                      })}
                    </Typography>
                  </>
                )}
              </Box>
            </CardContent>
          </>
        )}
      </CardActionArea>
    </Card>
  );
};
