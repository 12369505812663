import React, { FC } from 'react';
import { AppBar, Toolbar, Stack } from '@mui/material';

import { ListYourVenueLink } from './ListYourVenueLink';
import { MarketplaceHomePageLink } from '../../../components/MarketplaceHomePageLink';

export const NavigationBar: FC = () => {
  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: '#fff',
        boxShadow: 'none',
      }}
    >
      <Toolbar>
        <Stack
          direction="row"
          sx={{
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            height: '80px',
          }}
        >
          <MarketplaceHomePageLink />
          <ListYourVenueLink />
        </Stack>
      </Toolbar>
    </AppBar>
  );
};
