import React, { FC } from 'react';
import { Stack } from '@mui/material';
import { MapPin } from 'react-feather';
import { PVBlack } from '@pv/common/colors';
import type { VoidFn } from '../../../types/common';
import { HoverOverlayDiv } from './HoverOverlay';
import { LocationPicker } from '../pickers/LocationPicker';

export interface LocationSelectorProps {
  locationQuery: string;
  onChangeLocationQuery: VoidFn<string>;
}

export const LocationSelector: FC<LocationSelectorProps> = ({
  locationQuery,
  onChangeLocationQuery,
}: LocationSelectorProps) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      marginLeft="8px"
      position="relative"
      width="100%"
      sx={{
        '&:hover .HoverOverlay': {
          opacity: '0.2',
        },
        '&:focus-within .HoverOverlay': {
          opacity: '0.2',
        },
        '& .MuiAutocomplete-root .MuiOutlinedInput-root .MuiAutocomplete-endAdornment':
          {
            top: '15px',
            right: '15px',
          },
      }}
    >
      <MapPin
        color={PVBlack}
        style={{
          marginBottom: '16px',
        }}
      />
      <LocationPicker
        locationQuery={locationQuery}
        onChangeLocationQuery={onChangeLocationQuery}
        shouldShowOutlineSelector={false}
        shouldRenderIcon={false}
        size={'small'}
      />

      <HoverOverlayDiv className="HoverOverlay" style={{ left: '-12px' }} />
    </Stack>
  );
};
