import React, { FC, useEffect, useState } from 'react';
import {
  Navigate,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import { Stack, useMediaQuery, useTheme } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { useQuery } from '@apollo/client';

import { LoadingIndicator } from '@pv/common/components';

import { GoogleMap } from '../../components/GoogleMap';
import { buildAvailableSpacesRequest } from '../../helpers/api/spaces';
import { buildOrganizationCenterCoord } from '../../helpers/api/organizations';
import { AvailabilityFilterBar as FilterBar } from '../../components/FilterBar';
import type {
  EventStyle,
  GuestRange,
} from '../../types/components/filter_bar_types';
import {
  parseEventStyle,
  parseGuestRange,
} from '../../types/components/filter_bar_types';
import type { PartialVenue } from '../../types/models/venue_types';
import type { Nullable } from '../../types/common';
import { SpacesShelf } from '../../components/SpacesShelf';
import {
  fetchOrganization,
  fetchAvailableSpaces,
} from '../../helpers/api/graphql';
import { buildMappableAddressCoord } from '../../helpers/api/addresses';
import { FooterBar } from '../../components/FooterBar';
import {
  parseExpressBookSettingsFromVenues,
  parseHoursOfOperationFromVenues,
} from '../../helpers/api/organizations';
import { Space } from 'marketplace/src/types/models/space_types';
import styled from 'styled-components';

/*
 * Types.
 */

interface OrganizationSpacesPageProps {}

/*
 * Components.
 */

const LogoImg = styled.img`
  object-fit: contain;
  height: 100%;
`;

export const OrganizationSpacesPage: FC<OrganizationSpacesPageProps> = (
  params: OrganizationSpacesPageProps,
) => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const xlMedia = useMediaQuery(theme.breakpoints.only('xl'));
  const lgMedia = useMediaQuery(theme.breakpoints.only('lg'));
  const smMedia = useMediaQuery(theme.breakpoints.down('md'));
  const [searchParams, setSearchParams] = useSearchParams();
  const [eventDate, setEventDate] = useState<Nullable<Dayjs>>(
    searchParams.get('eventDate') ? dayjs(searchParams.get('eventDate')) : null,
  );
  const [startOffset, setStartOffset] = useState<Nullable<number>>(
    searchParams.get('startOffset')
      ? Number(searchParams.get('startOffset'))
      : null,
  );
  const [endOffset, setEndOffset] = useState<Nullable<number>>(
    searchParams.get('endOffset')
      ? Number(searchParams.get('endOffset'))
      : null,
  );
  const [eventStyle, setEventStyle] = useState<EventStyle>(
    (searchParams.get('eventStyle') as EventStyle) || 'seated_or_standing',
  );
  const [guestRange, setGuestRange] = useState<GuestRange>(
    (searchParams.get('guestRange') as GuestRange) || 'any_number',
  );

  const {
    data: organizationData,
    loading: organizationLoading,
    error,
  } = useQuery(fetchOrganization, {
    variables: {
      slug: slug,
    },
    skip: !slug,
  });

  const {
    data: availableSpacesData,
    refetch,
    loading: spacesLoading,
  } = useQuery(fetchAvailableSpaces, {
    variables: {
      organizationSlug: slug,
    },
    skip: !slug,
  });

  const buildMapWidth = () => {
    if (xlMedia) {
      return '25vw';
    }

    if (lgMedia) {
      return '33vw';
    }

    return '50vw';
  };

  const mapStyle = {
    width: buildMapWidth(),
    height: '78vh',
  };

  useEffect(() => {
    if (!slug) {
      return;
    }

    refetch(
      buildAvailableSpacesRequest({
        organizationSlug: slug,
        eventDate,
        startOffset,
        endOffset,
        eventStyle,
        guestRange,
      }),
    );
  }, [
    refetch,
    slug,
    eventDate,
    startOffset,
    endOffset,
    eventStyle,
    guestRange,
  ]);

  useEffect(() => {
    const params = {
      ...(eventDate ? { eventDate: eventDate.toISOString() } : {}),
      ...(startOffset !== null ? { startOffset: String(startOffset) } : {}),
      ...(endOffset !== null ? { endOffset: String(endOffset) } : {}),
      eventStyle,
      guestRange,
    };
    setSearchParams(params, { replace: true });
  }, [
    eventDate,
    startOffset,
    endOffset,
    eventStyle,
    guestRange,
    setSearchParams,
  ]);

  const organizationProfile = organizationData?.marketplaceOrganization;

  const mappableVenues = React.useMemo(() => {
    const availableSpaces = availableSpacesData?.availableSpaces || [];
    return organizationProfile?.venues.map((venue: PartialVenue) => ({
      key: venue.slug,
      name: venue.name,
      position: buildMappableAddressCoord(venue.address),
      spaces: availableSpaces?.filter(
        (space: Space) => space.venue.slug === venue.slug,
      ),
      images: venue.images,
      profileBanner: venue.profileBanner,
      address: venue.address,
      handleClick: () => navigate(`/venues/${venue.slug}`),
    }));
  }, [availableSpacesData, organizationProfile?.venues, navigate]);

  const renderProfileLogo = () => {
    const imgPath = organizationProfile?.logoUrl
      ? organizationProfile?.logoUrl
      : '/default_logo.png';

    return (
      <LogoImg
        alt={`${organizationProfile?.name} Logo`}
        src={imgPath}
        style={{ width: '300px', height: '150px' }}
      />
    );
  };

  const expressBookSettings = React.useMemo(() => {
    if (!organizationProfile) {
      return {
        minLeadTimeDays: 0,
        maxLeadTimeDays: 360,
        minEventDurationHours: 0,
        maxEventDurationHours: 24,
      };
    }
    return parseExpressBookSettingsFromVenues(organizationProfile);
  }, [organizationProfile]);

  if (organizationLoading) {
    return <LoadingIndicator />;
  }
  if (error && error.graphQLErrors[0]?.message === 'No organization found') {
    return <Navigate to="/not-found" replace={false} />;
  }
  return (
    <>
      <Stack
        direction="column"
        alignItems="center"
        gap="12px"
        sx={{
          boxShadow: '0px -1px 0px 0px rgba(0, 0, 0, 0.1) inset',
        }}
      >
        <Stack
          direction="column"
          alignItems="center"
          sx={{
            width: {
              sm: '96%',
              lg: 'auto',
            },
          }}
        >
          {renderProfileLogo()}

          <FilterBar
            eventDate={eventDate}
            onChangeEventDate={setEventDate}
            startTime={startOffset}
            onChangeStartTime={(e) => setStartOffset(Number(e.target.value))}
            clearStartTime={() => setStartOffset(null)}
            endTime={endOffset}
            onChangeEndTime={(e) => setEndOffset(Number(e.target.value))}
            clearEndTime={() => setEndOffset(null)}
            eventStyle={eventStyle}
            onChangeEventStyle={(e) =>
              setEventStyle(parseEventStyle(e.target.value))
            }
            clearEventStyle={() => setEventStyle('seated_or_standing')}
            guestRange={guestRange}
            onChangeGuestRange={(e) =>
              setGuestRange(parseGuestRange(e.target.value))
            }
            clearGuestRange={() => setGuestRange('any_number')}
            expressBookSettings={expressBookSettings}
            hoursOfOperation={parseHoursOfOperationFromVenues(
              organizationProfile,
            )}
          />
        </Stack>
      </Stack>
      <Stack direction="row">
        <Stack
          justifyContent="space-between"
          sx={{
            padding: '24px',
            maxHeight: '78vh',
            overflowY: 'scroll',
            width: {
              sm: '100vw',
              md: '50vw',
              lg: '67vw',
              xl: '75vw',
            },
          }}
        >
          {spacesLoading ? (
            <LoadingIndicator />
          ) : (
            <SpacesShelf
              spaces={availableSpacesData.availableSpaces}
              previewWidth="364"
              gridTemplateColumns={{
                xs: '1fr',
                sm: '1fr',
                md: '1fr',
                lg: 'repeat(2, 1fr)',
                xl: 'repeat(3, 1fr)',
              }}
            />
          )}
          <FooterBar />
        </Stack>
        {organizationProfile && !smMedia && mappableVenues && (
          <GoogleMap
            mapStyle={mapStyle}
            center={buildOrganizationCenterCoord(organizationProfile)}
            mappableItems={mappableVenues}
          />
        )}
      </Stack>
    </>
  );
};
