import React, { useEffect } from 'react';

import { Stack, useMediaQuery, useTheme, Button } from '@mui/material';

import { defaultCenter } from '../../../helpers/api/addresses';

import { GoogleMap } from '../../../components/GoogleMap';
import { LocationSearchFilterBar as FilterBar } from '../../../components/FilterBar';
import { FooterBar } from '../../../components/FooterBar';
import {
  parseEventStyle,
  parseGuestRange,
} from '../../../types/components/filter_bar_types';

import { useSearchResults } from '../providers/SearchResultsProvider';
import { useFilteredSearchResults } from '../providers/FilteredSearchResultsProvider';
import { useMappableVenues } from '../providers/MappableVenuesProvider';
import { SpacesList } from './SpacesList';
import { CategorySelector } from './CategorySelector';

export const SearchResults = () => {
  const theme = useTheme();
  const lgMedia = useMediaQuery(theme.breakpoints.up('lg'));
  const smMedia = useMediaQuery(theme.breakpoints.down('md'));

  const buildMapWidth = () => {
    if (lgMedia) {
      return '33vw';
    }

    return '50vw';
  };

  const mapStyle = {
    width: buildMapWidth(),
    height: '78vh',
  };

  const {
    locationQuery,
    setLocationQuery,
    loading,
    totalSpaceCount,
    spaces: allSpaces,
    center,
    hasMore,
    loadMore,
  } = useSearchResults();
  const {
    filteredSpaces,
    eventStyle,
    setEventStyle,
    guestRange,
    setGuestRange,
    selectedCategory,
    setSelectedCategory,
  } = useFilteredSearchResults();
  const spacesCount =
    totalSpaceCount - (allSpaces.length - filteredSpaces.length);

  useEffect(() => {
    if (hasMore && spacesCount > filteredSpaces.length) {
      loadMore();
    }
  }, [spacesCount, filteredSpaces.length, hasMore, loadMore]);

  const { mappableVenues } = useMappableVenues();

  return (
    <>
      <Stack
        direction="column"
        alignItems="center"
        sx={{
          width: '100%',
          boxShadow: '0px -1px 0px 0px rgba(0, 0, 0, 0.1) inset',
        }}
      >
        <FilterBar
          locationQuery={locationQuery}
          onChangeLocationQuery={(queryString: string) =>
            setLocationQuery(queryString || '')
          }
          eventStyle={eventStyle}
          onChangeEventStyle={(e) =>
            setEventStyle(parseEventStyle(e.target.value))
          }
          clearEventStyle={() => setEventStyle('seated_or_standing')}
          guestRange={guestRange}
          onChangeGuestRange={(e) =>
            setGuestRange(parseGuestRange(e.target.value))
          }
          clearGuestRange={() => setGuestRange('any_number')}
        />
        <CategorySelector
          selectedCategory={selectedCategory}
          setSelectedCategory={setSelectedCategory}
        />
      </Stack>
      <Stack direction="row">
        <Stack
          justifyContent="space-between"
          sx={{
            padding: '24px 24px 0px 24px',
            maxHeight: smMedia ? '90vh' : '78vh',
            overflowY: 'scroll',
            width: {
              sm: '100vw',
              md: '50vw',
              lg: '67vw',
            },
          }}
        >
          <SpacesList
            spacesCount={spacesCount}
            loading={loading}
            spaces={filteredSpaces || []}
          />
          {hasMore && !loading && (
            <Button onClick={loadMore} disabled={loading}>
              Load More
            </Button>
          )}

          <FooterBar />
        </Stack>
        {!smMedia && mappableVenues && (
          <GoogleMap
            mapStyle={mapStyle}
            mappableItems={mappableVenues}
            center={{
              lat: center?.latitude || defaultCenter.lat,
              lng: center?.longitude || defaultCenter.lng,
            }}
          />
        )}
      </Stack>
    </>
  );
};
