import React, { useState, createContext, useEffect, useCallback } from 'react';
import { useQuery, NetworkStatus } from '@apollo/client';
import { searchMarketplaceSpacesQuery } from '../graphql';
import { Space } from '../../../types/models/space_types';
import { debounce } from 'lodash';

import { useParams, useNavigate } from 'react-router-dom';

interface SpaceEdge {
  node: Space;
  cursor: String;
}

const defaultCity = 'San Francisco, CA';

const SearchResultsContext = createContext<{
  spaces: Space[];
  loading: boolean;
  locationQuery: string;
  setLocationQuery: (locationQuery: string) => void;
  totalSpaceCount: number;
  hasMore: boolean;
  loadMore: () => void;
  center: { latitude: number; longitude: number };
}>({
  spaces: [],
  loading: false,
  locationQuery: '',
  setLocationQuery: () => {},
  totalSpaceCount: 0,
  loadMore: () => {},
  hasMore: false,
  center: { latitude: 0, longitude: 0 },
});

export const SearchResultsProvider = ({
  children,
}: {
  children: JSX.Element[] | JSX.Element;
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const cityParam = params.city || defaultCity;
  const city = cityParam.replace(/-/g, ' ');
  const [locationQuery, setLocationQuery] = useState<string>(city);

  const variables = {
    query: locationQuery,
    first: 48,
  };

  const { data, loading, refetch, fetchMore, networkStatus } = useQuery(
    searchMarketplaceSpacesQuery,
    {
      variables: variables,
      skip: !locationQuery,
      fetchPolicy: 'cache-and-network',
      notifyOnNetworkStatusChange: true,
    },
  );

  const isFetchingMore = networkStatus === NetworkStatus.fetchMore;

  const spaceData =
    data?.marketplaceSpaceSearch?.locationQuery === locationQuery
      ? data?.marketplaceSpaceSearch
      : null;

  const center = spaceData?.center || {
    latitude: 0,
    longitude: 0,
  };
  const spaces =
    spaceData?.paginatedSpaces?.edges.map((edge: SpaceEdge) => edge.node) || [];

  const totalSpaceCount = spaceData?.paginatedSpaces.totalCount || 0;

  const loadMore = () => {
    fetchMore({
      variables: {
        ...variables,
        after: spaceData?.paginatedSpaces.pageInfo.endCursor,
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        if (!fetchMoreResult) return prev;
        const newEdges =
          fetchMoreResult.marketplaceSpaceSearch.paginatedSpaces.edges;
        const existingEdges = prev.marketplaceSpaceSearch.paginatedSpaces.edges;

        const mergedEdges = [...existingEdges, ...newEdges].reduce(
          (acc, edge) => {
            if (!acc.find((e: SpaceEdge) => e.cursor === edge.cursor)) {
              acc.push(edge);
            }
            return acc;
          },
          [],
        );
        const newPageInfo =
          fetchMoreResult.marketplaceSpaceSearch.paginatedSpaces.pageInfo;
        return {
          marketplaceSpaceSearch: {
            ...prev.marketplaceSpaceSearch,
            paginatedSpaces: {
              ...prev.marketplaceSpaceSearch.paginatedSpaces,
              edges: mergedEdges,
              pageInfo: newPageInfo,
              totalCount:
                fetchMoreResult.marketplaceSpaceSearch.paginatedSpaces
                  .totalCount,
            },
          },
        };
      },
    });
  };

  const handleScroll = useCallback(
    debounce(() => {
      if (
        window.innerHeight + document.documentElement.scrollTop >=
        document.documentElement.offsetHeight - 200
      ) {
        loadMore();
      }
    }, 200),
    [loadMore],
  );

  useEffect(() => {
    if (spaceData?.paginatedSpaces.pageInfo.hasNextPage) {
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
  }, [spaceData, handleScroll]);

  useEffect(() => {
    if (locationQuery === '') {
      return;
    }
    const formattedQuery = locationQuery.replace(/ /g, '-').replace(/,/g, '');
    if (formattedQuery === cityParam) {
      return;
    }

    navigate(`/cities/${formattedQuery}`, { replace: false });
    refetch();
  }, [locationQuery]);

  useEffect(() => {
    setLocationQuery(cityParam.replace(/-/g, ' '));
  }, [cityParam]);

  return (
    <SearchResultsContext.Provider
      value={{
        spaces,
        loading: loading || isFetchingMore,
        locationQuery,
        setLocationQuery,
        totalSpaceCount,
        hasMore: spaceData?.paginatedSpaces.pageInfo.hasNextPage,
        loadMore,
        center,
      }}
    >
      {children}
    </SearchResultsContext.Provider>
  );
};

export const useSearchResults = () => React.useContext(SearchResultsContext);
