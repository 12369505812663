import React from 'react';
import { Link, Stack, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

interface IBreadcrumb {
  spaceName?: string;
  spaceSlug?: string;
  venueName?: string;
  venueSlug?: string;
  organizationName?: string;
  organizationSlug?: string;
}

export const Breadcrumbs = ({
  breadcrumb,
  page,
}: {
  breadcrumb: IBreadcrumb;
  page: 'venue' | 'space';
}) => {
  const {
    organizationName,
    organizationSlug,
    venueName,
    venueSlug,
    spaceName,
    spaceSlug,
  } = breadcrumb;
  const breadcrumbs = [];

  if (organizationName && organizationSlug) {
    breadcrumbs.push({
      name: organizationName,
      path: `/organizations/${organizationSlug}`,
    });
  }

  if (venueName && venueSlug) {
    breadcrumbs.push({
      name: venueName,
      path: `/venues/${venueSlug}`,
      active: page === 'venue',
    });
  }

  if (spaceName && spaceSlug) {
    breadcrumbs.push({
      name: spaceName,
      path: `/venues/${venueSlug}/spaces/${spaceSlug}`,
      active: page === 'space',
    });
  }

  return (
    <Stack
      direction="row"
      gap="16px"
      sx={{ display: { xs: 'none', md: 'flex' } }}
    >
      {breadcrumbs.map((breadcrumb, i) => (
        <>
          {i !== 0 && (
            <Typography variant="body1" color="rgba(34, 34, 34, 0.60)">
              /
            </Typography>
          )}
          {breadcrumb.active ? (
            <Typography variant="body1" color="text.primary">
              {breadcrumb.name}
            </Typography>
          ) : (
            <Link
              component={RouterLink}
              to={breadcrumb.path}
              sx={{
                textDecoration: 'none',
                ':hover': {
                  textDecoration: 'underline',
                },
              }}
            >
              <Typography variant="body1" color="rgba(34, 34, 34, 0.60)">
                {breadcrumb.name}
              </Typography>
            </Link>
          )}
        </>
      ))}
    </Stack>
  );
};
