import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { Button, Stack, Box, Typography } from '@mui/material';
import { startCase } from 'lodash';

import {
  PVRed,
  PVBlack,
  PVGray,
  PVWhite,
  PVLightGray,
} from '@pv/common/colors';

import { guestRangeToDisplayNameMap } from '../selectors/GuestRangeSelector';
import type { FilterBarProps } from './index';
import { Icon } from '../../Icon';
import { MobileFilterBarDialog } from './MobileFilterBarDialog';
import { MapPin } from 'react-feather';

/*
 * Constants.
 */

const StyledIconBackgroundDiv = styled.div`
  width: 40px;
  height: 40px;
  background: ${PVRed};
  border-radius: 20px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

/*
 * Components.
 */

export const MobileFilterBar: FC<FilterBarProps> = ({
  locationQuery,
  onChangeLocationQuery,
  eventStyle,
  onChangeEventStyle,
  clearEventStyle,
  guestRange,
  onChangeGuestRange,
  clearGuestRange,
}: FilterBarProps) => {
  const [open, setOpen] = useState(false);
  const handleClick = (event: any) => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div role="search" style={{ width: '100%', padding: '12px 20px' }}>
      <Button
        sx={{
          height: '70px',
          padding: '0px',
          '&.MuiButton-root:hover': {
            backgroundColor: 'transparent',
          },
        }}
        fullWidth
        disableRipple
        onClick={handleClick}
      >
        <Stack
          direction="row"
          width="100%"
          sx={{
            alignItems: 'center',
            justifyContent: 'space-between',
            textAlign: 'left',

            padding: '10px',
            paddingLeft: '20px',

            borderRadius: '50px',
            border: `1px solid ${PVLightGray}`,
            background: PVWhite,
            gap: '8px',

            boxShadow: '0px 2px 14px 0px rgba(0, 0, 0, 0.10)',
          }}
        >
          <Stack direction="row" gap="12px" alignItems="center">
            <Box
              sx={{
                flexShrink: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                color: PVBlack,
              }}
            >
              <MapPin size={24} />
            </Box>
            <Stack direction="column">
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 500,
                  fontSize: '14px',
                  lineHeight: '143%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: '1',
                  WebkitBoxOrient: 'vertical',
                }}
              >
                {locationQuery}
              </Typography>
              <Typography
                variant="body2"
                color={PVGray}
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: '-webkit-box',
                  WebkitLineClamp: '1',
                  WebkitBoxOrient: 'vertical',
                }}
              >
                {`${startCase(eventStyle)}, ${
                  guestRangeToDisplayNameMap[guestRange]
                }`}
              </Typography>
            </Stack>
          </Stack>
          <StyledIconBackgroundDiv>
            <Icon name="filters" size={20} />
          </StyledIconBackgroundDiv>
        </Stack>
      </Button>
      <MobileFilterBarDialog
        isOpen={open}
        onClose={handleClose}
        locationQuery={locationQuery}
        onChangeLocationQuery={onChangeLocationQuery}
        eventStyle={eventStyle}
        onChangeEventStyle={onChangeEventStyle}
        clearEventStyle={clearEventStyle}
        guestRange={guestRange}
        onChangeGuestRange={onChangeGuestRange}
        clearGuestRange={clearGuestRange}
      />
    </div>
  );
};
