import type {
  EventStyle,
  GuestRange,
} from '../../../types/components/filter_bar_types';
import { guestRangeValuesMap } from '../../../types/components/filter_bar_types';

interface Space {
  seated?: number;
  standing?: number;
}

export const checkGroupSize = (
  space: Space,
  style: EventStyle,
  groupSize: GuestRange,
) => {
  if (!space) {
    return false;
  }
  if (groupSize === 'any_number') {
    return true;
  }

  const seatedCapacity = space.seated;
  const standingCapacity = space.standing;

  const [min, max] = guestRangeValuesMap[groupSize];
  const desiredCapacity = min;

  let seatedOk = true;
  let standingOk = true;

  if (typeof standingCapacity === 'number') {
    standingOk = standingCapacity >= desiredCapacity;
  }
  if (typeof seatedCapacity === 'number') {
    seatedOk = seatedCapacity >= desiredCapacity;
  }
  if (style === 'standing') {
    return standingOk;
  }

  if (style === 'seated') {
    return seatedOk;
  }

  if (style === 'seated_or_standing') {
    if (
      typeof seatedCapacity == 'number' &&
      typeof standingCapacity == 'number'
    ) {
      return standingOk || seatedOk;
    }
    if (typeof seatedCapacity == 'number') {
      return seatedOk;
    }
    if (typeof standingCapacity == 'number') {
      return standingOk;
    }
    return standingOk || seatedOk;
  }

  return true;
};
