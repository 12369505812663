import React, { useState } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { Alert, Box, Typography, Stack, Divider } from '@mui/material';
import { moneyPrint } from '@pv/common/utils';
import { NavigationBar } from '../NavigationBar';
import { StepCounter } from '../StepCounter';
import { FooterBar } from '../../../components/FooterBar';
import {
  TwoColumnLayout,
  ColumnContainer,
  PageContainer,
} from '../styledComponents';
import {
  CREATE_SQUARE_PAYMENT_METHOD_MUTATION,
  CONTRACT_QUERY,
  SPACE_BOOKINGS_QUERY,
  VENUE_BOOKINGS_QUERY,
  SEND_EXPRESS_BOOK_REQUEST_MUTATION,
} from '../graphql';
import { Section, SectionHeader } from './styledComponents';
import { CheckoutSummary } from './CheckoutSummary';
import { formatPercentageRate } from '../utils';
import { Policies } from './Policies';
import { SignProposal } from './SignProposal';
import { SendRequestButton } from './SendRequestButton';
import { StripeCreditCardForm } from './StripeCreditCardForm';
import { SquareCreditCardForm } from '../../../components/SquareCreditCardForm';
import { SquareTokenData } from '../../../types/components/square_credit_card_form_types';

enum PaymentProviders {
  STRIPE = 'stripe',
  SQUARE = 'square',
}

export const ExpressBookCheckoutForm = () => {
  const { venueSlug, spaceSlug, eventSlug } = useParams();
  const [URLSearchParams] = useSearchParams();
  const signatureFromParams = URLSearchParams.get('signature') || '';
  const [signature, setSignature] = useState(signatureFromParams);
  const [showPolicyModal, setShowPolicyModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [paymentMethodErrorMessage, setPaymentMethodErrorMessage] =
    useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const navigate = useNavigate();

  const { data: venueData } = useQuery(VENUE_BOOKINGS_QUERY, {
    variables: { venueSlug },
    skip: !venueSlug,
  });

  const venue = venueData?.marketplaceVenue;

  const { data: spaceData } = useQuery(SPACE_BOOKINGS_QUERY, {
    variables: { spaceSlug },
    skip: !spaceSlug,
  });

  const space = spaceData?.marketplaceSpace;

  const { data: contractData } = useQuery(CONTRACT_QUERY, {
    variables: { eventSlug },
    skip: !eventSlug,
    onCompleted: (data: any) => {
      if (data?.marketplaceEvent?.expressBookRequest?.status !== 'incomplete') {
        navigate(
          `/venues/${venueSlug}/spaces/${spaceSlug}/bookings/${eventSlug}/success`,
        );
      }
    },
    onError: (error) => {
      console.error(error);
      navigate(
        `/venues/${venueSlug}/spaces/${spaceSlug}/bookings/${eventSlug}`,
      );
    },
  });

  const expressBookRequest = contractData?.marketplaceEvent?.expressBookRequest;

  const showPaymentMethodForm =
    expressBookRequest &&
    expressBookRequest.status === 'incomplete' &&
    !expressBookRequest.paymentMethod;

  React.useEffect(() => {
    if (expressBookRequest && expressBookRequest.paymentMethod?.id) {
      handleSendExpressBookRequest(expressBookRequest.paymentMethod.id);
    }
  }, [expressBookRequest?.paymentMethod]);

  React.useEffect(() => {
    if (signatureFromParams) {
      setSignature(signatureFromParams);
    }
  }, [signatureFromParams]);

  const [sendExpressBookRequest] = useMutation(
    SEND_EXPRESS_BOOK_REQUEST_MUTATION,
  );

  const handleSendExpressBookRequest = async (paymentMethodId: string) => {
    if (!signature) {
      setErrorMessage('Please sign the agreement');
      return;
    }

    sendExpressBookRequest({
      variables: { input: { eventSlug, signature, paymentMethodId } },
      onCompleted: (data) => {
        if (
          data?.sendExpressBookRequest?.expressBookRequest?.status ===
          'incomplete'
        ) {
          setErrorMessage('An error occurred');
          setIsSubmitting(false);
        } else {
          navigate(
            `/venues/${venueSlug}/spaces/${spaceSlug}/bookings/${eventSlug}/success`,
          );
        }
      },
      onError: (error) => {
        setErrorMessage('An error occurred');
        setIsSubmitting(false);
      },
    });
  };

  const plannerId = contractData?.marketplaceEvent?.planner?.id;

  const event = contractData?.marketplaceEvent;
  const contract = event?.contract;

  const paymentProvider = event?.paymentProvider;
  const shouldUseStripe = paymentProvider === PaymentProviders.STRIPE;
  const shouldUseSquare = paymentProvider === PaymentProviders.SQUARE;

  const squareLocationId = event?.venue?.squareLocationId;
  const [tokenLoading, setTokenLoading] = useState(false);
  const [squarePaymentMethodId, setSquarePaymentMethodId] = useState('');

  const [
    createSquarePaymentMethod,
    { loading: createSquarePaymentMethodLoading },
  ] = useMutation(CREATE_SQUARE_PAYMENT_METHOD_MUTATION, {
    onError: (error) => {
      setPaymentMethodErrorMessage('An error occurred');
    },
  });

  const addSquarePaymentMethod = async (token: SquareTokenData) => {
    setTokenLoading(true);

    if (token.status !== 'OK') {
      return;
    }

    if (paymentProvider === PaymentProviders.SQUARE) {
      const input = {
        plannerId,
        venueId: event?.venue?.id,
        squareTokenId: token.token,
      };
      const variables = { input };
      createSquarePaymentMethod({
        variables,
        onCompleted: (data) => {
          const paymentMethodId =
            data?.createSquarePaymentMethod?.paymentMethod?.id;

          if (paymentMethodId) {
            setSquarePaymentMethodId(paymentMethodId);
          } else {
            setPaymentMethodErrorMessage('An error occurred');
          }
        },
      });
    } else {
      throw new Error();
    }
    setTokenLoading(false);
  };

  const venuePolicy = contract?.venuePolicy?.body;

  const getDepositPreamble = () => {
    const depositRate = contract?.depositRate;
    if (depositRate) {
      return `A ${formatPercentageRate(depositRate)} deposit of`;
    }

    return 'A deposit of';
  };

  const depositAmount = contract?.totals?.deposit || 0;

  const sendRequest = async (evt: React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();

    if (!signature) {
      setErrorMessage('Please sign the agreement');
      return;
    }

    if (shouldUseSquare && !squarePaymentMethodId) {
      setErrorMessage('Please add a payment method');
      return;
    }

    if (shouldUseStripe) {
      setIsSubmitting(true);
    }

    if (shouldUseSquare) {
      handleSendExpressBookRequest(squarePaymentMethodId);
      navigate(
        `/venues/${venueSlug}/spaces/${spaceSlug}/bookings/${eventSlug}/success`,
      );
    }
  };

  return (
    <Box>
      <NavigationBar />
      <PageContainer>
        <StepCounter activeStepIndex={2} />
        <TwoColumnLayout container={true} spacing={2}>
          <ColumnContainer item={true} xs={12} sm={6}>
            <form onSubmit={sendRequest}>
              <Stack spacing={2}>
                <Section>
                  <SectionHeader>Pay Deposit</SectionHeader>
                  <Typography variant="body1">
                    Add a payment method. {getDepositPreamble()}
                    <strong> {moneyPrint(depositAmount / 100)} </strong>
                    will be charged if the venue accepts your event.
                  </Typography>
                  {showPaymentMethodForm && shouldUseStripe && (
                    <StripeCreditCardForm
                      plannerId={plannerId}
                      onComplete={({ paymentMethodId }) => {
                        handleSendExpressBookRequest(paymentMethodId);
                      }}
                      isSubmitting={isSubmitting}
                      setIsSubmitting={setIsSubmitting}
                      setErrorMessage={setErrorMessage}
                      redirectUrl={`${window.location.href}?signature=${signature}`}
                    />
                  )}
                  {shouldUseSquare && squareLocationId && (
                    <SquareCreditCardForm
                      squareLocationId={squareLocationId}
                      confirmButtonText="Save Card"
                      onSubmit={addSquarePaymentMethod}
                      loading={tokenLoading || createSquarePaymentMethodLoading}
                    />
                  )}
                  {paymentMethodErrorMessage && (
                    <Alert severity="error">{paymentMethodErrorMessage}</Alert>
                  )}
                  {squarePaymentMethodId && (
                    <Alert severity="success">
                      Payment method added successfully
                    </Alert>
                  )}
                </Section>
                <Divider />
                <Policies
                  showPolicyModal={showPolicyModal}
                  setShowPolicyModal={setShowPolicyModal}
                  venuePolicy={venuePolicy}
                />
                <Divider />
                <Section>
                  <SignProposal
                    signature={signature}
                    setSignature={setSignature}
                    setShowPolicyModal={setShowPolicyModal}
                  />
                  {errorMessage && (
                    <Alert severity="error">{errorMessage}</Alert>
                  )}
                  <SendRequestButton loading={isSubmitting} />
                </Section>
              </Stack>
            </form>
          </ColumnContainer>
          <ColumnContainer item={true} xs={12} sm={6}>
            <CheckoutSummary
              venue={venue}
              space={space}
              event={event}
              contract={contract}
            />
          </ColumnContainer>
        </TwoColumnLayout>
        <FooterBar />
      </PageContainer>
    </Box>
  );
};
