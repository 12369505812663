import React, { FC } from 'react';
import { Box, Stack, Typography } from '@mui/material';

import { PVGray } from '@pv/common/colors';

import type { Space, Spaces } from '../../../types/models/space_types';
import { SpacePreview } from '../../../components/SpacePreview';
import { LoadingIndicator } from '../../../components/LoadingIndicator';

/*
 * Types.
 */

interface SpacesListProps {
  spaces: Spaces;
  spacesCount?: number;
  loading?: boolean;
}

/*
 * Components.
 */

export const SpacesList: FC<SpacesListProps> = ({
  spaces,
  spacesCount,
  loading = false,
}: SpacesListProps) => (
  <Stack direction="column" gap="24px">
    <Typography
      variant="h2"
      sx={{
        fontSize: '16px',
        lineHeight: '150%',
        fontWeight: 400,
      }}
    >
      <b>{spacesCount || spaces.length || 0}</b> Event Spaces
    </Typography>
    {!spaces.length && !loading && (
      <Stack
        direction="column"
        gap="18px"
        alignItems="center"
        justifyContent="center"
        sx={{ paddingTop: '20vh' }}
      >
        <img alt="" src="/bus.png" height="84px" />
        <Typography variant="body2" color={PVGray}>
          No spaces available
        </Typography>
      </Stack>
    )}
    {spaces.length > 0 && (
      <Box
        sx={{
          display: 'grid',

          gap: '24px',
          gridTemplateColumns: {
            xs: '1fr',
            sm: '1fr',
            md: '1fr',
            lg: 'repeat(2, 1fr)',
            xl: 'repeat(3, 1fr)',
          },
        }}
      >
        {spaces.map((space: Space) => (
          <SpacePreview key={space.slug} space={space} />
        ))}
      </Box>
    )}
    {loading && <LoadingIndicator />}
  </Stack>
);
