import React from 'react';
import {
  Typography,
  Link,
  Stack,
  useMediaQuery,
  useTheme,
} from '@mui/material';

export const ListYourVenueLink = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const textContent = isMobile ? 'List' : 'List your venue';

  return (
    <Link
      href="https://www.perfectvenue.com/"
      target="_blank"
      underline="hover"
    >
      <Stack
        direction="row"
        gap={1}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <img
          src="/store.png"
          alt=""
          style={{
            width: '24px',
            height: '24px',
          }}
        />
        <Typography component="span">{textContent}</Typography>
      </Stack>
    </Link>
  );
};
