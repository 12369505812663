import React, { FC } from 'react';
import { keys } from 'lodash';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import type { LocationSelectorProps } from '../selectors/LocationSelector';
import { LocationAutocomplete } from '../selectors/LocationAutocomplete';
/*
 * Types.
 */

interface LocationPickerProps extends LocationSelectorProps {
  shouldShowOutlineSelector: boolean;
  shouldRenderIcon: boolean;
  styleProps?: { [key: string]: string };
  size?: 'small' | 'medium';
}

/*
 * Constants.
 */

const removeOutlineStyle = {
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
};

/*
 * Components.
 */

export const LocationPicker: FC<LocationPickerProps> = ({
  locationQuery,
  onChangeLocationQuery,
  shouldShowOutlineSelector,
  shouldRenderIcon,
  styleProps,
  size,
}: LocationPickerProps) => {
  const style = {
    ...(styleProps ?? {}),
    ...(shouldShowOutlineSelector ? {} : removeOutlineStyle),
  };

  return (
    <FormControl fullWidth>
      <InputLabel
        htmlFor="location-query-select"
        id="location-query-select-label"
        shrink={true}
      >
        Where
      </InputLabel>
      <LocationAutocomplete
        locationQuery={locationQuery}
        onChange={onChangeLocationQuery}
        styleProps={style}
        size={size}
      />
    </FormControl>
  );
};
