interface CategoryMap {
  [key: string]: {
    spaceTypes: string[];
    venueTypes: string[];
    operator: 'and' | 'or';
  };
}

export const checkSpaceType = ({
  category,
  spaceType,
  venueType,
}: {
  category: string;
  spaceType?: string;
  venueType?: string;
}) => {
  if (!categoryMap[category]) {
    console.warn('Category not found:', category);
    return false;
  }
  if (!spaceType || !venueType) {
    return true;
  }
  const { spaceTypes, venueTypes, operator } = categoryMap[category];

  const spaceTypeMatches =
    spaceTypes.length > 0 && spaceTypes.includes(spaceType);
  const venueTypeMatches =
    venueTypes.length > 0 && venueTypes.includes(venueType);
  if (operator === 'and') {
    return spaceTypeMatches && venueTypeMatches;
  } else {
    return spaceTypeMatches || venueTypeMatches;
  }
};

export const defaultSpaceCategory = 'All Venues';

const categoryMap = {
  'All Venues': {
    operator: 'and',
    spaceTypes: [
      'Ballroom',
      'Bar',
      'Conference Room',
      'Courtyard',
      'Dining Room',
      'Full Buyout',
      'Lounge',
      'Mezzanine',
      'Patio',
      'Private Dining Room',
      'Semi-Private Space',
      'Event Space',
      'Bowling Lane',
      "Chef's Table",
      'Room',
      'Farm',
    ],
    venueTypes: [
      'Church / Religious Org',
      'Co-Working Space',
      'College / University',
      'Event Center',
      'Farm',
      'Museum',
      'Space Rental',
      'Sports Stadium',
      'Bar & Restaurant',
      'Brewery & Restaurant',
      'Distillery & Restaurant',
      'Restaurant',
      'Winery & Restaurant',
      'Wedding Venue',
      'None',
      'Bar',
      'Bowling Alley',
      'Brewery',
      'Coffee Shop',
      'Distillery',
      'Eatertainment',
      'Food Hall',
      'Golf Course',
      'Hotel',
      'Inn',
      'Other (Venue)',
      'Pickleball',
      'Resort',
      'Ski Resort',
      'Winery',
      'Mini Golf',
    ],
  },
  Restaurant: {
    operator: 'or',
    spaceTypes: [],
    venueTypes: [
      'Bar & Restaurant',
      'Brewery & Restaurant',
      'Distillery & Restaurant',
      'Restaurant',
      'Winery & Restaurant',
      'Wedding Venue',
    ],
  },
  Catering: {
    operator: 'or',
    spaceTypes: [
      'Catering',
      'Catering - Delivered',
      'Catering - Pick Up',
      'Food Truck',
    ],
    venueTypes: ['Caterer / Private Chef', 'Food Truck'],
  },
  'Space Rental': {
    operator: 'or',
    spaceTypes: [],
    venueTypes: [
      'Church / Religious Org',
      'Co-Working Space',
      'College / University',
      'Event Center',
      'Farm',
      'Museum',
      'Space Rental',
      'Sports Stadium',
    ],
  },
  Activity: {
    operator: 'or',
    spaceTypes: ['Activity'],
    venueTypes: [
      'Bowling Alley',
      'Entertainment',
      'Pickleball',
      'Activity',
      'Mini Golf',
      'Ski Resort',
      'Eatertainment',
      'Golf Course',
      'Museum',
    ],
  },
  Patio: {
    operator: 'or',
    spaceTypes: ['Patio'],
    venueTypes: [],
  },
  'Private Dining': {
    operator: 'or',
    spaceTypes: ['Private Dining Room'],
    venueTypes: [],
  },
} as CategoryMap;

const spaceTypes = [
  'None',
  'Activity',
  'Ballroom',
  'Bar',
  'Bowling Lane',
  'Catering',
  'Catering - Delivered',
  'Catering - Pick Up',
  'Conference Room',
  'Courtyard',
  'Dining Room',
  'Full Buyout',
  'Lounge',
  'Mezzanine',
  'Other (Non-Event Space)',
  'Patio',
  'Private Dining Room',
  'Semi-Private Space',
  'Large Group Reservation',
  'Event Space',
  'Bowling Lane',
  'Food Truck',
  'Court',
  "Chef's Table",
  'Room',
  'Virtual Event',
  'Farm',
];

const venueTypes = [
  'Church / Religious Org',
  'Co-Working Space',
  'College / University',
  'Event Center',
  'Farm',
  'Museum',
  'Space Rental',
  'Sports Stadium',
  'Bar & Restaurant',
  'Brewery & Restaurant',
  'Distillery & Restaurant',
  'Restaurant',
  'Winery & Restaurant',
  'Wedding Venue',
  'None',
  'Bar',
  'Bowling Alley',
  'Brewery',
  'Caterer / Private Chef',
  'Coffee Shop',
  'Distillery',
  'Eatertainment',
  'Food Hall',
  'Food Truck',
  'Golf Course',
  'Hotel',
  'Inn',
  'Other (Non-Venue)',
  'Other (Venue)',
  'Pickleball',
  'Resort',
  'Ski Resort',
  'Winery',
  'Activity',
  'Mini Golf',
];
