import { gql } from '@apollo/client';

export const fetchAvailableSpaces = gql`
  query AvailableSpaces(
    $organizationSlug: String
    $venueSlug: String
    $seated: [Int!]
    $standing: [Int!]
    $startDate: ISO8601Date
    $startOffset: Int
    $endOffset: Int
  ) {
    availableSpaces(
      organizationSlug: $organizationSlug
      venueSlug: $venueSlug
      seated: $seated
      standing: $standing
      startDate: $startDate
      startOffset: $startOffset
      endOffset: $endOffset
    ) {
      slug
      name
      description
      seated
      standing
      images(limit: 1) {
        id
        url
      }
      venue {
        id
        slug
        name
        images(limit: 1) {
          id
          url
        }
        profileBanner
        address {
          id
          city
          state
          latitude
          longitude
        }
      }
      pathname
    }
  }
`;

export const fetchOrganization = gql`
  query MarketplaceOrganization($slug: String!) {
    marketplaceOrganization(slug: $slug) {
      slug
      name
      logoUrl
      venues {
        id
        name
        slug
        address {
          latitude
          longitude
        }
        expressBookSettings {
          minLeadTimeDays
          maxLeadTimeDays
          minEventDurationHours
          maxEventDurationHours
        }
        hoursOfOperation {
          isOpen
          startTime
          endTime
          dayOfWeek
        }
      }
      latitude
      longitude
    }
  }
`;

export const fetchVenue = gql`
  query MarketplaceVenue($slug: String!) {
    marketplaceVenue(slug: $slug) {
      slug
      name
      description
      address {
        longitude
        latitude
        streetAddress1
        city
        state
        zip
        country
      }
      logo
      profileBanner
      helloLeadFormUrl
      websiteUrl
      menu {
        id
        menuItems(displayOnExpressBook: true) {
          id
        }
      }
      hoursOfOperation {
        dayOfWeek
        isOpen
        startTime
        endTime
      }
      expressBookSettings {
        minLeadTimeDays
        maxLeadTimeDays
        minEventDurationHours
        maxEventDurationHours
      }
      type
    }
  }
`;
