import { gql } from '@apollo/client';

export const searchMarketplaceSpacesQuery = gql`
  query MarketplaceSpaceSearch($query: String!, $first: Int, $after: String) {
    marketplaceSpaceSearch(query: $query) {
      id
      locationQuery
      center {
        latitude
        longitude
      }
      paginatedSpaces(first: $first, after: $after) {
        totalCount
        pageInfo {
          hasNextPage
          startCursor
          endCursor
        }
        edges {
          cursor
          node {
            id
            name
            description
            displayInDirectory
            seated
            standing
            slug
            type
            pathname
            images(limit: 1) {
              id
              url
              filename
            }
            venue {
              id
              name
              slug
              type
              address {
                id
                streetAddress1
                streetAddress2
                city
                state
                latitude
                longitude
              }
            }
          }
        }
      }
    }
  }
`;
