import React, { useState, createContext, useMemo } from 'react';
import { useSearchResults } from './SearchResultsProvider';
import { Space } from '../../../types/models/space_types';
import type {
  EventStyle,
  GuestRange,
} from '../../../types/components/filter_bar_types';
import {
  checkGroupSize,
  checkSpaceType,
  defaultSpaceCategory,
} from '../utils/filter';

const FilteredSearchResultsContext = createContext<{
  filteredSpaces: Space[];
  setGuestRange: (guestRange: GuestRange) => void;
  setEventStyle: (eventStyle: EventStyle) => void;
  setSelectedCategory: (category: string) => void;
  eventStyle: EventStyle;
  guestRange: GuestRange;
  selectedCategory: string;
}>({
  filteredSpaces: [],
  setGuestRange: () => {},
  setEventStyle: () => {},
  setSelectedCategory: () => {},
  eventStyle: 'seated_or_standing',
  guestRange: 'any_number',
  selectedCategory: defaultSpaceCategory,
});
export const FilteredSearchResultsProvider = ({
  children,
}: {
  children: JSX.Element[] | JSX.Element;
}) => {
  const { spaces } = useSearchResults();
  const [eventStyle, setEventStyle] =
    useState<EventStyle>('seated_or_standing');
  const [guestRange, setGuestRange] = useState<GuestRange>('any_number');
  const [selectedCategory, setSelectedCategory] =
    useState<string>(defaultSpaceCategory);

  const filterSpace = (space: any) => {
    const isBigEnough = checkGroupSize(space, eventStyle, guestRange);
    const fitsCategory = checkSpaceType({
      category: selectedCategory,
      spaceType: space.type || 'None',
      venueType: space.venue.type || 'None',
    });
    return isBigEnough && fitsCategory;
  };

  const filteredSpaces = useMemo(() => {
    return spaces.filter((space) => {
      return filterSpace(space);
    });
  }, [spaces, eventStyle, guestRange, selectedCategory]);

  return (
    <FilteredSearchResultsContext.Provider
      value={{
        filteredSpaces: filteredSpaces,
        setGuestRange,
        setEventStyle,
        setSelectedCategory,
        eventStyle,
        guestRange,
        selectedCategory,
      }}
    >
      {children}
    </FilteredSearchResultsContext.Provider>
  );
};

export const useFilteredSearchResults = () =>
  React.useContext(FilteredSearchResultsContext);
