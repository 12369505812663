import React, { FC } from 'react';
import { Box, Divider, Stack } from '@mui/material';

import { EventStyleSelector } from '../selectors/EventStyleSelector';
import { GuestRangeSelector } from '../selectors/GuestRangeSelector';
import { LocationSelector } from '../selectors/LocationSelector';
import type { FilterBarProps } from '.';

/*
 * Components.
 */

export const WebFilterBar: FC<FilterBarProps> = ({
  locationQuery,
  onChangeLocationQuery,
  eventStyle,
  onChangeEventStyle,
  guestRange,
  onChangeGuestRange,
}: FilterBarProps) => (
  <Box
    sx={{
      padding: '12px 20px',
      width: '100%',
      maxWidth: '960px',
    }}
  >
    <Box
      role="search"
      height="70px"
      sx={{
        padding: '16px 8px 0px 16px',
        margin: '0px',

        borderRadius: '50px',
        border: '1px solid #E6E6E6',
        background: '#FFF',

        boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.1)',

        display: 'grid',
        gridTemplateColumns: '33% 33% 33%',
        alignItems: 'center',
      }}
    >
      <Stack direction="row" justifyContent="space-between">
        <LocationSelector
          locationQuery={locationQuery}
          onChangeLocationQuery={onChangeLocationQuery}
        />
        <Separator />
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ padding: '0px 10px' }}
      >
        <EventStyleSelector
          eventStyle={eventStyle}
          onChangeEventStyle={onChangeEventStyle}
        />
        <Separator />
      </Stack>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ padding: '0px 0px 0px 8px' }}
      >
        <GuestRangeSelector
          guestRange={guestRange}
          onChangeGuestRange={onChangeGuestRange}
        />
      </Stack>
    </Box>
  </Box>
);

const Separator: FC = () => (
  <Divider orientation="vertical" flexItem sx={{ marginBottom: '10px' }} />
);
