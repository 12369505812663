import React, { FC } from 'react';
import {
  Box,
  Button,
  IconButton,
  Dialog,
  Stack,
  Typography,
} from '@mui/material';
import { X } from 'react-feather';

import type { FilterBarProps } from './index';
import type { Procedure } from '../../../types/common';
import { EventStylePicker } from '../pickers/EventStylePicker';
import { GuestRangePicker } from '../pickers/GuestRangePicker';
import { LocationPicker } from '../pickers/LocationPicker';

/*
 * Types.
 */

interface MobileFilterBarDialogProps extends FilterBarProps {
  isOpen: boolean;
  onClose: Procedure;
}

/*
 * Components.
 */

export const MobileFilterBarDialog: FC<MobileFilterBarDialogProps> = ({
  isOpen,
  onClose,
  locationQuery,
  onChangeLocationQuery,
  eventStyle,
  onChangeEventStyle,
  clearEventStyle,
  guestRange,
  onChangeGuestRange,
  clearGuestRange,
}: MobileFilterBarDialogProps) => {
  const handleClearSelection = () => {
    onChangeLocationQuery('');

    if (clearEventStyle) {
      clearEventStyle();
    }
    if (clearGuestRange) {
      clearGuestRange();
    }
  };

  const renderDialogHeader = () => (
    <Stack
      direction="row"
      gap="20px"
      alignItems="center"
      justifyContent="flexStart"
      sx={{ margin: '16px 20px', width: '100%' }}
    >
      <IconButton onClick={onClose} title="close">
        <X />
      </IconButton>
      <Typography variant="h6" sx={{ width: '100%' }}>
        Filter
      </Typography>
    </Stack>
  );

  const renderDialogFooter = () => (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      padding="24px"
      sx={{ boxShadow: '0px 1px 0px 0px rgba(0, 0, 0, 0.10) inset' }}
    >
      <Button
        color="primary"
        variant="outlined"
        sx={{ textTransform: 'none' }}
        onClick={handleClearSelection}
      >
        Clear Selections
      </Button>
      <Button
        color="secondary"
        variant="contained"
        sx={{ textTransform: 'none' }}
        onClick={onClose}
      >
        Apply Filter
      </Button>
    </Stack>
  );

  const renderDialogBody = () => (
    <Stack direction="column" gap="24px" padding="24px" alignItems="center">
      <Stack direction="column" gap="16px" width="100%" maxWidth="368px">
        <Typography variant="h6">Location</Typography>
        <LocationPicker
          locationQuery={locationQuery}
          onChangeLocationQuery={onChangeLocationQuery}
          styleProps={{ height: '56px' }}
          shouldShowOutlineSelector
          shouldRenderIcon
        />
      </Stack>
      <Stack direction="column" gap="16px" width="100%" maxWidth="368px">
        <Typography variant="h6">Style</Typography>
        <EventStylePicker
          eventStyle={eventStyle}
          onChangeEventStyle={onChangeEventStyle}
          styleProps={{ height: '56px' }}
          shouldShowOutlineSelector
          shouldRenderIcon
        />
      </Stack>
      <Stack direction="column" gap="16px" width="100%" maxWidth="368px">
        <Typography variant="h6">Guests</Typography>
        <GuestRangePicker
          guestRange={guestRange}
          onChangeGuestRange={onChangeGuestRange}
          styleProps={{ height: '56px' }}
          shouldShowOutlineSelector
          shouldRenderIcon
        />
      </Stack>
    </Stack>
  );

  return (
    <Dialog open={isOpen} onClose={onClose} fullScreen>
      <Stack direction="column" justifyContent="space-between" height="100%">
        <Stack direction="column">
          {renderDialogHeader()}
          {renderDialogBody()}
        </Stack>
        {renderDialogFooter()}
      </Stack>
    </Dialog>
  );
};
